import { useState, FormEvent } from "react";
import Main from "./Main"; // Import your Main component
import logo from "./assets/logo512S.png";
import { checkPassword } from "./reusable/utils/checkPassword";

export default function App() {
  const [password, setPassword] = useState<string>("");
  const [passwordPassed, setPasswordPassed] = useState<boolean>(false);

  const handlePassword = async (event: FormEvent) => {
    event.preventDefault();
    const passed = await checkPassword(password);
    if (passed) {
      setPasswordPassed(true);
    } else {
      alert("Incorrect password");
    }
  };

  return passwordPassed ? (
    <Main />
  ) : (
    <div className="flex flex-col items-center justify-center min-h-screen space-y-3">
      <img
        src={logo}
        className="object-contain rounded-md shadow-md h-52 w-52"
        alt="logo"
      />
      <div className="text-3xl font-semibold">Thoughtfolio.ai</div>
      <form onSubmit={handlePassword}>
        <input
          type="password"
          className="px-3 py-2 border rounded-md outline-none"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter Password"
        />
      </form>
    </div>
  );
}
