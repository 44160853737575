import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBeZJhLF614F2280rcpv72xmYF8lIFOV1w",
  authDomain: "thoughtfolioai.firebaseapp.com",
  projectId: "thoughtfolioai",
  storageBucket: "thoughtfolioai.appspot.com",
  messagingSenderId: "928954681595",
  appId: "1:928954681595:web:7f05b7adbc227ba692885e",
  measurementId: "G-V50HTY4434",
};

const app = initializeApp(firebaseConfig);

let analytics;
if (app.name && typeof window !== "undefined") {
  analytics = getAnalytics(app);
}

const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, analytics, functions, db, storage };
