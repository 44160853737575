import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase.config";

export const checkPassword = async (password: string): Promise<boolean> => {
  try {
    const checkPasswordFunction = httpsCallable(functions, "checkPassword");
    const result = await checkPasswordFunction({ password });
    return result.data as boolean;
  } catch (error) {
    console.error("Error checking password:", error);
    throw error;
  }
};
